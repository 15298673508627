import React, { useEffect } from "react";
import { navigate } from "gatsby";

import { Confirmation } from "../components/common";

import DownloadConfirmationImage from "../assets/images/confirmations/subscriber.svg";
import Layout from "../components/layout";

function ConfirmToDownloadPage() {
  useEffect(() => {
    if (typeof window === "undefined" || !window?.history?.state?.email) {
      navigate("/");
    }
  });

  return (
    <Layout>
      <Confirmation
        subTitle="Email Sent"
        title="Please Check Your Email to Confirm And Download"
        description={
          <>
            It will arrive in a moment. Open the email, click on the big purple{" "}
            <strong>Confirm Email And Download</strong> button. If you use
            gmail, find the email in promotions or updates tab. Otherwise, check
            the spam folder.
          </>
        }
        image={DownloadConfirmationImage}
        instruction="Showing how to confirm your email and download"
      />
    </Layout>
  );
}

export default ConfirmToDownloadPage;
